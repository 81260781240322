<template>
    <div>
      高德
      <div id="container"></div>
    </div>
</template>

<script>
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  data() {
    return {
      // 地图对象
      AMap: null,
      // 地图实例对象
      map: null,
      // 经纬度
      location: {
        lat: "",
        lng: "",
      },
      geolocation: {},
    };
  },
  unmounted() {
    this.map?.destroy();
  },
  mounted() {
    this.initAMap();
  },
  methods: {
    initAMap() {
      AMapLoader.load({
        key: "10fb2bcc51fa73e1da3bcf0776be2b17", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        resizeEnable: true,
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map("container", {
            zoom: 13, // 初始化地图级别
          });
          // console.log(this.map.getCenter());
          // 进行定位
          this.processing()
        })
        .catch((e) => {
          console.log(e);
        });
    },
    // 进行定位
    processing() {
      console.log(1);
      var _this = this
      const AMap = this.AMap;
      AMap.plugin("AMap.Geolocation", function () {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 10000, //超过10秒后停止定位，默认：5s
          buttonPosition: "RB", //定位按钮的停靠位置
          buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
        })
        _this.map.addControl(geolocation)
        geolocation.getCurrentPosition(function (status, result) {
          if (status == "complete") {
           console.log(result);
          } else {
            // onError(result)
            console.log(result);
            // alert('定位失败，请使用百度浏览器或者UC浏览器')
          }
        })
      })
    },



  },
};
</script>
